.academy {
  &__type-label {
    padding: .5rem 1rem;
    background: #1194F3;
    color: #fff;
  }

  &__time {
    color: #58207A;
    padding-right: 1rem;
    font-size: 1.15rem;
  }

  .label-course {
    background: #C70C7C;
  }
}

.filters {
  .selectInput {
    margin-bottom: 0;
  }
}
