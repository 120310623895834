.profile-progress-circle-wrapper {
  max-width: 14rem;
  position: relative;

  .profile-progress-circle-tip {
    background: #FFFFFF;
    box-shadow: 0 0 1rem rgba(36, 36, 44, 0.25);
    border-radius: .5rem;
    color: #383C61;
    padding: .5rem;
    white-space: nowrap;
    position: absolute;
    left: 4.5rem;
    top: -0.6rem;
    z-index: 2;
    display: none;

    &:after {
      position: absolute;
      display: block;
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: .5rem .5rem 0 .5rem;
      border-color: #ffffff transparent transparent transparent;
      bottom: -.5rem;
      left: 2rem;
    }
  }

  &:hover {
    .profile-progress-circle-tip {
      display: block;
      cursor: pointer;
    }
  }
}

.profile-progress-circle-text {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  b {
    font-size: 2rem;
    text-align: center;
    background-color: #2253A0;
    color: transparent;
    text-shadow: 2px 2px 3px rgba(255,255,255,0.3);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;

    span {
      position: relative;
      display: block;
      line-height: 0;
      font-size: small;
      top: 0.3rem;
      color: #2253A0;
    }
  }
}
