.datepicker {

  &__label {
    margin-bottom: .5rem;
    padding: 0;
    opacity: .7;
    position: relative;
    display: flex;
    justify-content: space-between;
  }

  &__custom-input {
    padding: .5rem;
    border-radius: .4rem;
    text-decoration: none;
    text-align: left;
    color: #2b2b2b;
    border: 2px solid #3a84d0;
    position: relative;
    width: 100%;
    min-width: 10rem;
    min-height: 38px;
  }

  &__icon {
    position: absolute;
    top: .5rem;
    right: 0.5rem;
    font-size: 1.2rem;
  }
}


.react-datepicker-wrapper {
  width: 100%;
}