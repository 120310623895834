.iframe-container {
  overflow: hidden;
  height: 100%;

  iframe {
    -moz-transform-origin: top left;
    -webkit-transform-origin: top left;
    -o-transform-origin: top left;
    -ms-transform-origin: top left;
    transform-origin: top left;

    &.noClick {
      pointer-events: none;
    }
  }

}
