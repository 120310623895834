.header-menu {
  a {
    color: #fff;
    text-decoration: none;
  }
}

.layout-content {
  display: flex;
  flex-flow: column;
  width: 100%;
  padding: 1rem 1.5rem;
}
//.layout-content-wide {
//  padding-left: 25%;
//}
