.horizontal-line {
  height: 1px;
  width: 100%;
  background: linear-gradient(0deg, #6170C0, #6170C0), #FFFFFF;
}

input:disabled {
  background: linear-gradient(0deg, rgba(239, 243, 250, 0.2), rgba(239, 243, 250, 0.2));
  border: 2px solid #D1D7E4;
  box-sizing: border-box;
}