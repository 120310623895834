.alert-message {
  right: 20px;
  width: 350px;
  bottom: 20px;
  position: fixed;
  z-index: 10000;

  border-radius: 4px;
  user-select: none;
  background: #3a84d0;

  &__info {
    font-size: 13px;
    color: #f0f0f0;
    padding: 10px 30px 13px 65px;
  }
  &:before {
    border-radius: 50%;
    position: absolute;
    left: 17px;
    display: block;
    content: " ";
    top: 50%;
    margin-top: -8px;
    height: 13px;
    width: 13px;
    z-index: 20;
  }
  &.success:before {
    background: #57a096;
  }
  &.error:before {
    background: #f35958;
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    color: #888888;
    opacity: 1;
    font-weight: bold;
    display: block;
    font-size: 20px;
    line-height: 20px;
    padding: 8px 10px 7px 7px;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
  }

}

