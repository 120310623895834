@import "../../../styles/variables";

.Textarea {
    margin-bottom: 1.5rem;

    label {
        margin-bottom: .5rem;
        padding: 0;
        opacity: .7;
        position: relative;
        display: flex;
        justify-content: space-between;
    }

    textarea {
        display: block;
        border: 2px solid $essilor-custom-color-blue;
        box-sizing: border-box;
        border-radius: .4rem;
        padding: .5rem 1rem;
        width: 100%;
        outline: none;
        transition: all 300ms ease-in-out;
        resize: none;
    }


    .error-icon {
        color: red;
        font-size: 1.5rem;
    }

}

