.permission-users {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  width: 100%;
  margin: 0 -1rem;

  @media (max-width: 768px) {
    & {
      margin: 0;
    }
  }

  &__type {
    flex: 1 1 0;
    margin: 0 1rem;

    @media (max-width: 768px) {
      & {
        margin: 0;
      }
    }
  }

  &__col {
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0px 0px 8px rgba(36, 36, 44, 0.08);
    border-radius: 1rem;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-content: flex-start;
    color: #383C61;
    align-items: center;

    &-name {
      flex: 2 1 50%;
    }
    &-phone {
      flex: 1;
      border-left: 1px solid #3E4FB1;
      padding-left: 2rem;
      margin-left: 2rem;
      padding-top: .5rem;
      padding-bottom: .5rem;
      white-space: nowrap;
    }
  }
  &__title {
    color:#FBDA64;
    font-weight: bold;
    margin-top: 2rem;
    margin-bottom: .5rem;
  }
}
