@import "../../../styles/variables";

.selectInput {
    margin-bottom: 1.5rem;

    label {
        margin-bottom: .5rem;
        padding: 0;
        display: flex;
        opacity: .7;
        justify-content: space-between;
    }

    & &__control {
        border-radius: 0.4rem;
        border: 2px solid $essilor-custom-color-blue;
    }

    > div {
        border-radius: 0.4rem;
    }

    > div {
        color: #2b2b2b;
    }


    .error-icon {
        color: red;
        font-size: 1.5rem;
    }
}
