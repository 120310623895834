.files-list {

  &__item {

    border-bottom: 1px #fff dashed;
    padding-bottom: 10px;
    margin-bottom: 10px;

    &:last-of-type {
      border-bottom: 0px;
    }

    i {
      margin-right: .8rem;
      font-size: 2rem;

      &.icon-file-pdf {
        color: #2253A0;
      }

      &.icon-file-doc {
        color: #56A7DB;
      }

      &.icon-file-image {
        color: #57A096;
      }

    }

    &-title {
      font-weight: 500;
      font-size: 18px;
      line-height: 44px;
      color: #383C61;
    }

    &-link {
      display: block;
      float: right;
      color: #3A84D0;
      font-size: 24px;
      margin-top: 10px;
    }

  }

}
