$essilor-custom-color-blue: #3A84D0;
$essilor-custom-color-grey: #757575;

$essilor-text-color-black: #19212f;
$essilor-text-color-grey: #757575;
$essilor-text-color-blue: #3A84D0;
$essilor-text-color-dark-blue: #2253A0;


$essilor-bg-color-blue: #3a84d0;


$essilor-text-color-fail: #e20000;
$essilor-bg-color-fail: #F83232;