.education-right-column {
  flex-basis: 30rem;

  .white-box {
    background: rgba(255, 255, 255, 0.8);
  }
}

.submenu-education {
  width: 100%;
  max-width: 50rem;

  & li {
    width: 100%;
    display: block;
    margin-bottom: 1rem;
    background: rgba(232, 240, 253, 0.5);
    border: 1px solid #3A84D0;
    height: 10rem;
    color: #3A84D0;
    font-style: normal;
    font-weight: bold;
    font-size: 1.2rem;
    align-items: center;
    text-transform: uppercase;
    text-decoration: none;
    padding: 1rem;
    vertical-align: middle;
    overflow: hidden;


    a {
      text-decoration: none;
      height: 100%;
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding-left: 1rem;
      align-items: center;
    }

    img {
      height: 100%;

      @media (max-width: 680px) {
        display: none;
      }

    }
  }

}


.items-result {

  &__item {
    &-title {

      a {
        text-decoration: none;
      }

    }
  }

}


.courses {
  &__item {
    padding: 2.5rem 1.4rem;
  }

  &__item-status {
    border-radius: 0px;
    padding: 5px 20px;

    &--start {
      background: linear-gradient(0deg, #0BB1BC, #0BB1BC), linear-gradient(180deg, #47C2D6 0%, #1A29AE 100%);
    }

    &--continue {
      background: linear-gradient(0deg, #522180, #522180), linear-gradient(0deg, #24A791, #24A791), linear-gradient(180deg, #47C2D6 0%, #1A29AE 100%);
    }

    &--finish {
      background: linear-gradient(0deg, #EA5A2A, #EA5A2A), linear-gradient(0deg, #522180, #522180), linear-gradient(0deg, #24A791, #24A791), linear-gradient(180deg, #47C2D6 0%, #1A29AE 100%);
    }

  }
}

.course-description {

  position: relative;

  &__title {
    color: #19212F;
    text-transform: uppercase;
  }

  &__text {
    color: #383C61;
  }

  &__points {
    background: linear-gradient(180deg, #098BAC 9.37%, #42C774 100%), linear-gradient(180deg, #E05F00 0%, #F2B004 100%), linear-gradient(180deg, #286ACD 0%, #158EED 100%), #1194F3;
    border: 3px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px rgba(56, 60, 97, 0.5);
    width: 60px;
    height: 60px;
    color: #fff;
    border-radius: 50%;
    text-align: center;
    font-size: 1.5rem;
    padding-top: 0.5rem;
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;

    &__text {
      font-size: 0.4rem;
      margin-top: -8px;
    }
  }

}


.course-steps {
  margin-left: 0.5rem;
  margin-top: 2rem;

  &__item {
    margin-bottom: 1rem;
    width: 100%;
    max-width: 500px;
    padding: 10px 20px;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    background: #2253A0;
    cursor: default;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--active {
      cursor: pointer;
      background-color: #56a7db;
    }
  }
}

.course-step-navigation {
  background: rgba(232, 240, 253, 0.4);
}

.modal-content {
  &_link {
    text-align: center;
    text-decoration-line: underline;
    color: #858FCA;
  }
}
