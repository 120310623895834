.sales-stat-row-wrapper_complex {
  font-weight: bold;
}

.sales-stat-cell-wrapper {
  position: relative;

  .sales-stat-cell-wrapper-tip {
    background: #FFFFFF;
    box-shadow: 0 0 1rem rgba(36, 36, 44, 0.25);
    border-radius: .5rem;
    color: #383C61;
    padding: .5rem;
    white-space: nowrap;
    position: absolute;
    left: -.4rem;
    top: -3rem;
    z-index: 2;
    display: none;

    &:after {
      position: absolute;
      display: block;
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: .5rem .5rem 0 .5rem;
      border-color: #ffffff transparent transparent transparent;
      bottom: -.5rem;
      left: 2rem;
    }
  }

  &:hover {
    .sales-stat-cell-wrapper-tip {
      display: block;
      cursor: pointer;
    }
  }
}

.sales-stat-cell-wrapper-red {
  color: red !important;
}

.sales-stat-cell-wrapper-green {
  color: #24a791 !important;
}