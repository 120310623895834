.conditionFormat {
  .selectInput {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: baseline;
    label {
      white-space: nowrap;
      padding-right: 2rem;
      color: #fff;
    }
    > div {
      width: 100%;
    }
  }

}
