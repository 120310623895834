.loginPage {
  background-repeat: no-repeat;
  background-attachment: fixed;
  min-height: 100vh;
}

.btnOrange {
  padding: .5rem 3rem;
  width: 50%;
  margin: 0 2rem;
}

@media (max-width: 640px) {
  .btnOrange {
    padding: 1.5rem 3rem;
    width: 90%;
    margin: 1rem auto;
  }
}