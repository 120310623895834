.profile-progress-bar-wrapper {
  position: relative;
}
.profile-progress-bar {
  position: absolute;
  z-index: 2;
  height: calc(100% - 10px);
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 1.1rem;

  > div {
    width: 33.3%;
    color: #fff;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: baseline;

    span {
      text-align: left;
    }
    b {
      font-size: 1.75rem;
      margin-right: .75rem;
    }
    strong {
      margin-right: .75rem;
    }
    i {
      font-style: normal;
    }
    .icon-check-mark {
      font-size: 1.1rem;
    }
    .mark-point {
      display: none;
      line-height: 0;
      font-style: normal;
      font-size: .8rem;
      position: relative;
      top: .5rem;
      color: #fff;
    }

    &.active {
      color: #fff;

      .mark-point {
        display: block;
      }

      .icon-check-mark {
        display: none;
      }
    }
    &.inactive {
      color: #19212F;
      b {
        color: #19212F;
      }
      .mark-point {
        display: none;
      }
      .icon-check-mark {
        display: none;
      }
    }
  }

  &.sm {
    > div {
      align-items: flex-start;

      span {
        display: flex;
        flex-direction: column;
      }
      .mark-point {
        line-height: normal;
        position: static;
      }
      .dash {
        display: none;
      }
    }
  }
}


@media (max-width: 640px) {
  .profile-progress-bar > div {
    span {
      font-size: .5rem;
    }
    b {
      font-size: 1.5rem;
    }
    strong {
      font-size: .9rem;
    }
    .icon-check-mark {
      font-size: 0.8rem;
      position: relative;
    }
  }
}

@media (max-width: 500px) {
  .profile-progress-bar > div {
    padding-left: 18px;

    flex-direction: column;
    .icon-check-mark, .mark-point, i {
      display: none !important;
    }
  }
}
@media (max-width: 400px) {
  .profile-progress-bar > div:nth-child(-n+2){
    padding-left: 5px;
  }
  .profile-progress-bar > div strong {
    font-size: .5rem;
  }
}
