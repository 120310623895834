.progress-bar-wrapper {
  width: 100%;
  background: #6A73A4;
  border-radius: 10px;
  height: 10px;
  margin-top: 5px;

    .progress-bar-line {
      background: linear-gradient(0deg, #1DC9B7, #1DC9B7), linear-gradient(0deg, #FF881A, #FF881A), linear-gradient(0deg, #55C2FF, #55C2FF), #6A73A4;
      border-radius: 10px;
      height: 10px;
    }

}

.cert-wrapp {
  li {
    max-width: 30rem;
    margin-top: .8rem;
    cursor: pointer;

    img {
      max-width: 100%;
    }

    &.inactive {
      filter: grayscale(1);
      cursor: not-allowed !important;
    }
  }
}

.user-photo {
  width: 80%;
}

.white-box {
  &__header {
    border-bottom:2px dashed #f0f0f0;
    width: auto;
    padding-bottom: 10px;
    font-family: 'ProximaNova-Semibold', sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.12em;
    font-size: 1.3rem;
  }

  &__content {
    margin-top: 10px;
  }
}

.courses-box__header {
  border-bottom:2px dashed #f0f0f0;
  width: auto;
  padding-bottom: 10px;
  font-family: 'ProximaNova-Semibold', sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.12em;
  font-size: 1.3rem;
  margin-bottom: 10px;
}

.news-short {
  display: flex;
  flex-flow: wrap row;
  justify-content: space-between;
  margin-top: 20px;

  &__item {
    width: 47%;
    margin-bottom: 20px;
    padding-bottom: 20px;

    &__border-bottom {
      border-bottom: 1px dashed #B2B2B2;
    }


    img {
      float: left;
      width: 100px;
      height: 100px;
      margin-right: 20px;
      object-fit: cover;
      object-position: top center;
    }

    small {
      font-size: 12px;
    }

    &-title {
      font-family: 'ProximaNova-Semibold', sans-serif;
      text-transform: uppercase;
      letter-spacing: 0.12em;
      font-size: 1.1rem;
      text-decoration: none;
      margin-bottom: 5px;
    }

    &-text {
      display: block;
      font-size: 12px;
      text-decoration: none;
    }

  }
}


.educations-short {

  &__item {
    border-bottom: 1px #fff dashed;
    padding-bottom: 10px;
    margin-bottom: 10px;

    &:last-child {
      border-bottom: 0px #fff dashed;
    }

    &__left {
      position: relative;

      .btn {
        width: 80%;
        font-size: 1rem;
        padding: 0.6rem 1rem;
      }

    }

    &__right {
      text-align: right;

      img {
        max-width: 200px;
        float: right;
      }

    }

  }

}


@media (max-width: 768px) {
  .news-short {
    &__item {
      width: 100%;
    }
  }
}


.modal-cert-wrapp {
  width: 500px;
  text-align: center;
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;

  img {
    margin-bottom: 2rem;
  }

  .modal-cert-name {
    position: absolute;
    top: 185px;
    font-size: 18px;
    text-align: center;
    width: 100%;
  }
}

.cert-fast_start {
  .modal-cert-name {
    top: 160px;
  }
}

.cert-essilor_professional_doc {
  .modal-cert-name {
    top: 160px;
  }
}

.cert-essilor_professional_con {
  .modal-cert-name {
    top: 165px;
  }
}