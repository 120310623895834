.custom-label {
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  input:checked + svg {
    display: block !important;
  }

  .select-none {
    opacity: .7;
    width: 100%;
  }

  .error-icon {
    color: red;
    font-size: 1.5rem;
  }
}


.checkbox {
  background: none;
  border: 2px solid #3a84d0;
  border-radius: .4rem;
}





