.rbc-calendar-container {
  width: 100%;
  height: 40vw;
  @media (max-width: 1300px) {
    & {
      height: 60vw;
    }
  }
  @media (max-width: 1024px) {
    & {
      height: 80vw;
    }
  }
  @media (max-width: 768px) {
    & {
      height: auto;
    }
  }
}
.rbc-calendar {
  width: 100%;

  .rbc-toolbar .rbc-toolbar-label {
    color:#3A84D0;
    font-weight: 500;
    text-transform: uppercase;

    @media (max-width: 768px) {
      & {
        text-align: left;
        font-size: 1rem;
      }
    }
  }

  .rbc-month-view, .rbc-agenda-view {
    border: 0;
  }

  .rbc-time-view, .rbc-month-row, .rbc-agenda-content {
    background: rgba(232, 240, 253, 0.4);

    .rbc-off-range-bg {
      background: #fff;
    }

    .rbc-agenda-event-cell {
      padding: 0;

      .event {
        padding: .5rem 1rem;
        &__title {
          text-align: left;
          font-size: 1rem;
          line-height: 1.2rem;
        }
      }
    }
  }

  .rbc-header + .rbc-header {
    border: 0;
  }

  .rbc-month-header, .rbc-agenda-view > .rbc-agenda-table {
    background-color: #56A7DB;
    border: 0;
    border-radius: 1.4rem 1.4rem 0 0;

    .rbc-header {
      color: #fff;
      padding: 1rem 0;
      font-weight: 400;
      text-transform: capitalize;
    }
  }

  .rbc-agenda-table .rbc-header {
    text-align: center;
  }
  .rbc-agenda-empty {
    background: #fff;
    padding: .5rem 1rem;
  }


  .rbc-date-cell a  {
    font-weight: bold;
  }
  .rbc-date-cell.rbc-off-range a  {
    color: #B9BEDC;
  }

  .rbc-toolbar {
    flex-flow: row-reverse;
    button {
      border: 0;
      color: #3A84D0;

      &:hover, &:focus, &:active {
        background: none;
        outline: none;
        box-shadow: none;
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .rbc-row-segment .rbc-event-content {
    height: 100%;
    cursor: default;
    display: flex;
    flex-direction: column;
  }
  .rbc-row-content {
    height: 100%;

    .rbc-row:nth-child(2) {
      position: relative;
      z-index: -1;
      top: -20px;
      height: 100%;

      .rbc-event {
        height: 100%;
      }
    }

    .rbc-row:nth-child(3) {
      position: relative;
      z-index: -1;
      top: -70%;
      height: 100%;

      .rbc-event {
        height: 100%;
      }
    }
  }

  .rbc-event {
    background: none;
  }
  .rbc-event:focus {
    outline: none;
  }

  .event {
    background: #FFEEC3;
    height: 100%;
    display: flex;
    flex-flow: column;
    cursor: pointer;

    &:last-child {
      border-top: 1px dotted;
    }
    &:first-child {
      border-top: 0;
    }


    &__type {
      font-weight: bold;
      padding-left: 1rem;
      position: relative;
      font-size: 11px;
      &:before {
        position: absolute;
        display: block;
        content: '';
        border-radius: 20px;
        width: 5px;
        height: 5px;
        top: 50%;
        margin-top: -3px;
        left: 5px;
      }

      &-online:before {
        background: #1DC9B7;
      }
      &-offline:before {
        background: #FF0000;
      }
    }

    &__title {
      padding: 1%;
      padding-left: 6px;
      white-space: normal;
      font-size: 71%;
      line-height: 120%;
    }
    &__time {
      text-align: center;
      padding: 3px;
      white-space: normal;
      font-size: 11px;
    }

    &__button {
      background: #F1801E;
      width: 100%;
      padding: 4px 0;
      font-weight: normal;
      text-align: center;
      color: #fff;
      margin-top: auto;
      cursor: pointer;
      font-size: 10px;
    }

    &-joined {
      background: #DDEFFF;

      .event__button {
        background: #1D5DBF;
      }
    }
  }
}

.calendar-legend {
  display: flex;
  flex-flow: row;
  width: 100%;
  padding: 1rem 0;

  li {
    display: flex;
    align-items: center;
    padding-right: 2rem;
    &:last-child {
      padding-right: 0;
    }
  }

  &__title {
    padding-left: .5rem;
  }

  &__status {
    display: block;
    width: 2rem;
    height:1rem;
    &-joined {
      background: #DDEFFF;
    }
    &-not-joined {
      background: #FFEEC3;
    }
  }
  &__type {
    display: block;
    content: '';
    border-radius: 20px;
    width: 5px;
    height: 5px;
    &-online {
      background: #1DC9B7;
    }
    &-offline {
      background: #FF0000;
    }
  }
}


.rbc-month-row + .rbc-month-row {
  border-bottom: 0px solid #ddd;
  border-top: 0px solid #ddd;
}

.rbc-month-row {
  border-bottom: 1px solid #ddd !important;
  border-left: 1px solid #ddd !important;
  border-right: 1px solid #ddd !important;
}

.rbc-calendar .rbc-date-cell a {
  color: #2253A0;
}