@import "../../../styles/variables";

.uploadedhomeworks {
  &__title {
    text-transform: uppercase;
  }

  &__item {
    background-color: #56a7db;
    border-radius: .4rem;
    padding: 0.5rem 1rem;

    &-title {
      border-right: 1px solid #C4C4C4;
    }

    &-status {
      padding-left: 1rem;

      &-fail {
        color: $essilor-text-color-fail;
      }
    }
  }
}
