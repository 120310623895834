@import "../../../styles/variables";

.btn {
  color: #fff;
  padding: 1rem 3rem;
  border-radius: .4rem;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  font-family: 'ProximaNova-SemiBold', sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.12em;
  font-size: 0.9rem;


  &-circle {
    border-radius: 50rem;
  }

  &-thin {
    padding: .5rem 3rem;
  }

  &-narrow {
    padding: .5rem 1rem;
  }

  &-orange {
    background: linear-gradient(180deg, #EC6012 0%, #E2271B 100%);
    &:hover {
      background: linear-gradient(180deg, #EC6012 0%, #E2271B 90%);
    }
  }
  &-blue {
    background-color: #3A84D0;
    &:hover {
      background-color: #2253A0;
    }
  }
  &-green {
    background: linear-gradient(180deg, #44C971 0%, #0586AF 100%);
    box-shadow: 0 10px 20px rgba(66, 187, 120, 0.3);
    &:hover {
      background: linear-gradient(180deg, #44C971 0%, #0586AF 90%);
      box-shadow: 0 5px 10px rgba(66, 187, 120, 0.3);
    }
  }
  &-yellow {
    background: linear-gradient(180deg, #FDDF69 0%, #DF7800 100%);
    box-shadow: 0 10px 20px rgba(251, 218, 100, 0.3);
    &:hover {
      background: linear-gradient(180deg, #FDDF69 0%, #DF7800 90%);
      box-shadow: 0 5px 10px rgba(251, 218, 100, 0.3);
    }
  }
  &-orange-yellow {
    background: linear-gradient(180deg, rgba(255, 199, 0, 0.77) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(180deg, #EC6012 0%, #E2271B 100%), #E53019;
    box-shadow: 0 12px 20px rgba(236, 94, 19, 0.3);
    &:hover {
      background: linear-gradient(180deg, rgba(255, 115, 11, 0.77) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(180deg, #EC6012 0%, #E2271B 100%), #E53019;
      box-shadow: 0 5px 10px rgba(236, 94, 19, 0.3);
    }
  }
  &-grey, &:disabled {
    border:1px solid #B2B2B2;
    color: #B2B2B2;
    &:hover {
      border:1px solid $essilor-custom-color-grey;
      color: $essilor-custom-color-grey;
    }
  }

  &-perple {
    background: #51217F;
  }

  &-steelblue {
    background: #9DA5D3;
  }

  &-dark-blue {
    background: linear-gradient(0deg, #6572C1, #6572C1), linear-gradient(180deg, #44C971 0%, #0586AF 100%);

    &:hover {
      background: linear-gradient(0deg, #323F8C, #323F8C), linear-gradient(180deg, #44C971 0%, #0586AF 100%);
    }
  }

  &:disabled {
    cursor: default !important;
    border:1px solid #B2B2B2 !important;
    color: #B2B2B2 !important;
    background: none !important;

    &:hover {
      cursor: default !important;
      border:1px solid #B2B2B2 !important;
      color: #B2B2B2 !important;
      background: none !important;
    }
  }
}
