.trainer {
  &__type-label {
    padding: .5rem 1rem;
    background: #1194F3;
    color: #fff;
  }

  &__type-label-course {
    background: #24a791;;
  }
}
