.Menu {
  background-color: rgb(243, 243, 243);
  color: #19212F;
  padding: 0;
  margin: 0;
  display: flex;
  flex-flow: column;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    padding-top: 1rem;

    li {

      a {
        padding: 1rem 2rem;
        margin: .5rem 0;
        display: flex;
        text-decoration: none;
        align-items: center;

        svg, i {
          margin-right: 1rem;
          font-size: 1.3rem;
        }

        &:hover, &.active {
          background-color: #DBDADA;
          svg {
            fill: #19212F;
          }
        }
      }


    }
  }
  &-closed {
    width: 4.5rem;
    ul {
      li {
        padding-left: .5rem;
        padding-right: .5rem;
        a {
          text-align: center;
          padding-left: 0;
          padding-right: 0;
          span {
            display: none;
          }
          i {
            margin: 0 auto;
          }

          &:hover, &.active {
            border-radius: .5rem;
            margin-left: 0;
            padding-left: 0;
          }
        }
      }
    }
  }

  &__feedback {
    background-color: #57A096;
    border-radius: 12px;
    color:#fff;
    padding: 1rem 1.2rem;
    text-transform: uppercase;
    text-decoration: none;
    position: fixed;
    right: 1rem;
    top: 5rem;
    z-index: 9;

    &:hover {
      background-color: #28655c;
      cursor: pointer;
    }

    &:focus {
      outline: none;
    }
  }
}

@media (max-width: 768px) {
  .Menu {
    position: absolute;
    z-index: 10;
    width: 100%;
    flex-direction: column-reverse;

    ul li a.active {
      border-radius: 0;
      margin-left: 0;
      padding: 1rem 2rem;
    }

    &-closed {
      display: none;
    }

    &__feedback {
      border-radius: 0;
      position: static;

    }
  }
}
