.news-result {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  align-content: flex-start;
  margin: 0 -.5rem;

  @media (max-width: 768px) {
    & {
      margin: 0;
    }
  }

  &__item {
    padding-bottom: 2rem;
    width: calc(50% - 1rem);
    margin:.5rem;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    position: relative;

    &.box-shadow {
      box-shadow: 0px 0px 15px rgba(36, 36, 44, 0.12);
    }

    @media (max-width: 768px) {
      & {
        width: calc(100% - 1rem);
        margin: .5rem 0;
      }
    }


    .btn {
      width: 60%;
      display: block;
      padding: .7rem 3rem;
      margin-top: auto;
    }

    &-title {
      margin-top: 1rem;
      font-size: 1.2rem;
      font-weight: 600;
      text-transform: uppercase;
      color: #3A84D0;
    }

    img {
      object-fit: cover;
      object-position: top center;
      float: left;
      height: 12rem;
      width: 13rem;
      margin-right: 2rem;
      margin-bottom: 1rem;
    }

    &-content {
      padding-left: 1.4rem;
      padding-right: 1.4rem;
      padding-top: 1.4rem;
      margin-bottom: 2rem;
    }

    .bottom-content {
      background: rgb(242, 247, 255);
      padding: 10px;
      position: absolute;
      bottom: 0px;
      text-transform: uppercase;
      color: #56A7DB;

      a {
        text-decoration: none;
      }
    }

    &:nth-child(1), &:nth-child(2) {
      .news-result__item-content {
        flex-flow: column;
        display: flex;

        small {
          color: #2253A0;
        }
      }
      img {
        margin: 1rem 0;
        height: 19rem;
        width: 100%;
        order: 1;
      }
      .news-result__item-text {
        order: 2;
      }

    }
  }

  &__stat {

    position: relative;
    float: right;
    &-dropdown {
      display: none;
      position: absolute;
      right: 0;
      top: 1.5rem;
      width: 12rem;
      height: 15rem;
      background: #fff;
      box-shadow: -2px 2px 10px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      color: #383C61;
      z-index: 2;
      padding: 1rem 0;

      &-small {
        height: 4.5rem;
      }

      &-item {
        color: #383C61;
        display: block;
        border-bottom: 1px dashed #9DA5D2;
        margin: 0 1rem .5rem 1rem;
        padding: 0 0 .5rem 0;
        text-align: left;
      }
    }



    &:hover .news-result__stat-dropdown {
      display: block;
    }


    .scrollarea  {
      height: 13rem;
      padding-right: 8px;

      .scrollbar-container.vertical {
        background: #CBD0ED;
        border-radius: 5px;
        opacity: 1;
        width: 4px;
        right: 6px;
      }

      .scrollbar-container.vertical .scrollbar {
        background: #3E4FB1;
        border-radius: 5px;
        width: 4px;
        margin-left: 0;
      }
    }
  }

}



