.profile-progress-line-wrapper {
  display: flex;
  flex-direction: column;
}
.profile-progress-line-title {
  text-align: left;
  color: #19212F;
  margin-bottom: .5rem;
  text-transform: uppercase;
}
.profile-progress-line-total {
  text-align: right;
  color: #2253A0;
}
.profile-progress-line {
  position: relative;
  width: 100%;
  border-bottom: 1rem;
  padding: .23rem;
  background: #DBDADA;
  border-radius: 1rem;

  &__progress {
    box-shadow: inset 7px 7px 15px rgba(34, 37, 66, 0.35);
    border-radius: 20px;
    height: 1.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #44F9B8;
    background-image: url(/assets/resources/profile-progress-line.png), linear-gradient(0deg, #7CBFB6, #7CBFB6), linear-gradient(90deg, #44F9B8 0%, #08B69C 99.08%), linear-gradient(90deg, #E8552B 1.48%, #FFCF24 100%);
    background-image: -webkit-image-set(url(/assets/resources/profile-progress-line.png) 1x, url(/assets/resources/profile-progress-line@2x.png) 2x), linear-gradient(0deg, #7CBFB6, #7CBFB6), linear-gradient(90deg, #44F9B8 0%, #08B69C 99.08%), linear-gradient(90deg, #E8552B 1.48%, #FFCF24 100%);
    background-repeat: repeat-x;
    color: #fff;
    font-weight: bold;
    text-align: center;

    span {
      line-height: 0;
      white-space: nowrap;
    }

    &.green {
      background: #3A84D0;
      background-image: url(/assets/resources/profile-progress-line.png);
      background-image: -webkit-image-set(url(/assets/resources/profile-progress-line.png) 1x, url(/assets/resources/profile-progress-line@2x.png) 2x);
      background-repeat: repeat-x;
    }

    &.small {
      span {
        position: absolute;
        left: 0.5rem;
      }
    }
  }



}
