.brands-menu  {
  border: 1px solid #DBDADA;
  box-sizing: border-box;
  border-radius: 1rem;

  list-style: none;
  margin: 0;
  padding: 0 2rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;

  div {
    position: relative;
    display: block;
    padding: 1rem;

    &:hover:before, &:hover:after, &.active:before, &.active:after {
      background-color: #3A84D0;
      position: absolute;
      width: 100%;
      content: '';
      display: block;
    }
    &:hover:before, &.active:before {
      top: 0;
      height: .4rem;
    }
    &:hover:after, &.active:after {
      bottom: 0;
      height: .3rem;
    }
  }
}

