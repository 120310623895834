.tests-component {
  .wrong-test {
    background: #FFDADA;
    position: relative;
    padding-top: 3rem;

    &__label {
      position: absolute;
      color: #fff;
      background: #F83232;
      border-radius: 0 0 0 .5rem;
      padding: .5rem 1rem;
      right: 0;
      top: 0;
    }
  }
}
