.lenses-group {
  padding-bottom: 2rem;
  width: calc(50% - 1rem);
  margin: .5rem;
  text-decoration: none;
  color: #fff;
  display: flex;
  flex-direction: column;

  @media (max-width: 767px) {
    width: 100%
  }

  h3 {
    background-color: #7552EC;
    background: linear-gradient(180deg, #7552EC 0%, #8A4BFA 100%);
    color: #fff;
    text-align: center;
    font-weight: normal;
    font-size: 18px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15%;
    padding-right: 15%;
    margin-bottom: 20px;
  }

  .lenses {
    padding: 30px;

    .lens {
      margin-bottom: 20px;

      &__img {
        float: left;
        width: 20%;

        img {
          object-fit: contain;
        }
      }

      &__content {
        float: right;
        width: 75%;

        .lens__more {
          color: #2727A3;
          cursor: pointer;
        }
      }

      .float-none {
        clear: both;
      }
    }
  }

  &+.multy-cover {
    h3 {
      background: linear-gradient(180deg, #31A3C5 0%, #3CC6BE 100%);
    }
  }

  &+.modify-by-owner-multy-cover {
    .lenses {
      .lens:first-child {
        .lens__text {
          text-shadow: 0px 4px 10px #22F1F9;
        }
        .lens__img {
          filter: drop-shadow(0px 5px 4px #22F1F9);
        }
      }
    }
  }

  &+.harmful-light {
    h3 {
      background: linear-gradient(180deg, #5291F2 0%, #53B3FD 100%);
    }
  }
  &+.modify-by-owner-harmful-light {
    .lenses {
      .lens:first-child {
        .lens__text {
          text-shadow: 0px 4px 10px #17ACFF;
        }
        .lens__img {
          filter: drop-shadow(0px 5px 4px #17ACFF);
        }
      }
    }
  }

  &+.glare {
    h3 {
      background: linear-gradient(180deg, #A854CC 0%, #D14FDA 100%);
    }
  }
  &+.modify-by-owner-glare {
    .lenses {
      .lens:first-child {
        .lens__text {
          text-shadow: 0px 4px 10px #C318FF;
        }
        .lens__img {
          filter: drop-shadow(0px 5px 4px #C318FF);
        }
      }
    }
  }
}


.podbirator-detail {

  &__content {
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 8px rgba(36, 36, 44, 0.08);
    padding: 1.4rem;
    min-height: 400px;

    img {
      margin-top: -1.4rem;
      margin-right: -1.4rem;
      width: 30rem;
    }
  }

  &__title {
    font-weight: bold;
    font-size: 2rem;
    color: #51217F;
  }

  &__under-title {
    color: #51217F;
    margin-top: -0.5rem;
    font-size: 1rem;
  }

  &__text {
    p {
      margin-bottom: 2rem;
    }
  }
}


.modify-by-owner-design-age {
  .lenses {
    .lens:first-child {
      .lens__text {
        text-shadow: 0px 4px 10px #6117FF;
      }

      .lens__img {
        filter: drop-shadow(0px 5px 4px #6117FF);
      }
    }
  }
}