@media (max-width: 768px) {
    .custom-table {
        max-width: none !important;
    }
}

.custom-table {
    width: 100%;
    max-width: calc(100vw - 150px);
    overflow-x: scroll;
    box-shadow: 0px 0px 8px rgba(36, 36, 44, 0.08);

    table {
        width: 100%;
        min-width: 700px;

        th {
            background-color: #56A7DB;
            color: #fff;
            padding: 1rem;
            font-weight: normal;
            text-transform: uppercase;

            a {
                text-decoration: none;

                &:hover {
                    cursor: pointer;
                }
            }
        }

        tbody {
            tr {
                td {
                    padding: 1rem 2rem;
                    background: #ECECEC;
                    color: #383C61;
                    border-left: 1px dashed #DBDADA;
                }
            }

            tr:nth-child(odd) {
                td {
                    background: #ffffff;
                }
            }
        }
    }
}

.education-caption__current {
    color: #383C61;
    font-weight: bold;
}

.education-caption__max {
    color: #737693;
}

.sort-direction {
    padding-left: 0.3rem;
    font-size: 0.2rem;
}
