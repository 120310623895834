@font-face {
  font-family: 'icomoon';
  src:  url('/assets/fonts/icomoon.eot?rah0cj');
  src:  url('/assets/fonts/icomoon.eot?rah0cj#iefix') format('embedded-opentype'),
  url('/assets/fonts/icomoon.ttf?rah0cj') format('truetype'),
  url('/assets/fonts/icomoon.woff?rah0cj') format('woff'),
  url('/assets/fonts/icomoon.svg?rah0cj#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-alert-circle:before {
  content: "\e92f";
}
.icon-alert-triangle:before {
  content: "\e902";
}
.icon-check:before {
  content: "\e900";
}
.icon-dollar-sign:before {
  content: "\e91f";
}
.icon-download:before {
  content: "\e915";
}
.icon-file:before {
  content: "\e913";
}
.icon-gift:before {
  content: "\e924";
}
.icon-info:before {
  content: "\e930";
}
.icon-list:before {
  content: "\e922";
}
.icon-lock:before {
  content: "\e916";
}
.icon-mail:before {
  content: "\e917";
}
.icon-map-pin:before {
  content: "\e918";
}
.icon-message-square:before {
  content: "\e91d";
}
.icon-more-horizontal:before {
  content: "\e914";
}
.icon-phone:before {
  content: "\e919";
}
.icon-send:before {
  content: "\e923";
}
.icon-smile:before {
  content: "\e920";
}
.icon-star:before {
  content: "\e91a";
}
.icon-thumbs-up:before {
  content: "\e921";
}
.icon-upload-cloud:before {
  content: "\e925";
}
.icon-user:before {
  content: "\e91b";
}
.icon-x:before {
  content: "\e901";
}
.icon-sort-asc:before {
  content: "\e92b";
}
.icon-sort-desc:before {
  content: "\e92c";
}
.icon-file-video:before {
  content: "\e938";
}
.icon-permissions:before {
  content: "\e933";
}
.icon-share:before {
  content: "\e934";
}
.icon-home:before {
  content: "\e935";
}
.icon-bell:before {
  content: "\e936";
}
.icon-sales:before {
  content: "\e937";
}
.icon-arrow-filled-down:before {
  content: "\e931";
}
.icon-arrow-filled-up:before {
  content: "\e932";
}
.icon-trophy:before {
  content: "\e92d";
}
.icon-check-mark:before {
  content: "\e92e";
}
.icon-clock:before {
  content: "\e92a";
}
.icon-question-feedback:before {
  content: "\e91e";
}
.icon-podbirator:before {
  content: "\e926";
}
.icon-padlock:before {
  content: "\e91c";
}
.icon-file-pdf:before {
  content: "\e910";
}
.icon-file-doc:before {
  content: "\e911";
}
.icon-file-image:before {
  content: "\e912";
}
.icon-promo:before {
  content: "\e908";
}
.icon-education:before {
  content: "\e90f";
}
.icon-arrow:before {
  content: "\e903";
}
.icon-arrow-l:before {
  content: "\e927";
}
.icon-arrow-t:before {
  content: "\e928";
}
.icon-arrow-b:before {
  content: "\e929";
}
.icon-settings:before {
  content: "\e904";
}
.icon-logout:before {
  content: "\e905";
}
.icon-search:before {
  content: "\e906";
}
.icon-statistics:before {
  content: "\e907";
}
.icon-menu:before {
  content: "\e909";
}
.icon-card:before {
  content: "\e90a";
}
.icon-users:before {
  content: "\e90b";
}
.icon-calendar:before {
  content: "\e90c";
}
.icon-glass:before {
  content: "\e90d";
}
.icon-books:before {
  content: "\e90e";
}
.icon-arrowleft:before {
  content: "\2190";
}
.icon-arrowright:before {
  content: "\2192";
}
