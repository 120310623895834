.table-entity {
    background: #fff;
    box-shadow: 0px 0px 8px rgba(36, 36, 44, 0.08);
    padding-bottom: 1rem;

    table {
        border: 0;
        width: 100%;

        tbody {
            tr:nth-child(odd) {
                td {
                    background: #ececec;
                    border-left: 1px solid #ffff;
                    border-right: 1px solid #ffff;

                    &:first-child {
                        border-left: 0;
                    }
                    &:last-child {
                        border-right: 0;
                    }
                }
            }
        }

        td {
            padding: .8rem;
        }
    }

    &__first-column {
        padding-right: 0;
        width: 1px;
    }

    .custom-label {
        display: block;
        margin-bottom: 0;
        .checkbox {
            border: 1px solid #1194F3;
            margin-right: 0;
        }
        input:checked + svg {
            color: #1194F3;
        }
    }

    .icon-check {
        color: #1194F3;
    }
}
