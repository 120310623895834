.InputFile {
    margin-bottom: 1.5rem;

    label {
        margin-bottom: .5rem;
        padding: 0;
        opacity: .7;
        position: relative;
        display: flex;
        justify-content: space-between;
    }

    &__input {
        position: relative;
    }

    .input-file {
        display: block;
        box-sizing: border-box;
        width: 100%;
        opacity: 0;
        z-index: 2;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
    }

    .input-fake {
        display: block;
        background: linear-gradient(0deg, #EFF3FA, #EFF3FA), #FFFFFF;
        color: #2b2b2b;
        border: 1px solid #D1D7E4;
        box-sizing: border-box;
        border-radius: .4rem;
        padding: .5rem .8rem;
        width: 100%;
        outline: none;
        transition: all 300ms ease-in-out;
        padding-right: 11.4rem;


        min-height: 2.7rem;

        &__text {
            width: 100%;
            word-break: break-word;
            background: #dfe0ea;
            margin: 3px 0;
            border-radius: .2rem;
            padding: 2px 12px;
        }
        &__button {
            position: absolute;
            right: 0;
            top: 0;
            padding-top: 0;
            padding-bottom: 0;
            display: flex;
            align-items: center;
            cursor: pointer;
            height: 100%;
            border-radius: .4rem;
        }
    }


    .error-icon {
        color: red;
        font-size: 1.5rem;
    }

}

