.background-main {
  background: url(/assets/resources/background-main-pro.jpg) 0 0 no-repeat;
  background-size: cover;
}

.logo-energy {
  padding: 2rem;
  right:0;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;

}

.logos-main {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  position: absolute;
  padding: 2rem;
  right:0;
  top: 0;
  width: 100%;
  align-items: flex-start;

  img {
    margin: 0 .5rem;
  }

}

@media (max-width: 768px) {
  .background-main {
    background: none;
  }

  .logos-main {
    position: relative;
  }

  .main-page {

  }
}


