.custom-label {
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  input:checked + div {
    background: #fff;
    position: relative;
    border-color: #3A84D0;

    & > div {
      position: absolute;
      width: 1rem;
      height: 1rem;
      left: 50%;
      top: 50%;
      margin-left: -.5rem;
      margin-top: -.5rem;
      background: #3A84D0;
      border-radius: 9999px;
    }
  }

  .select-none {
    opacity: .7;
    width: 100%;
  }

  .error-icon {
    color: red;
    font-size: 1.5rem;
  }
}

.custom-label:hover {
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  input + div {
    background: #fff;
    position: relative;
    border-color: #3A84D0;

    & > div {
      position: absolute;
      width: 1rem;
      height: 1rem;
      left: 50%;
      top: 50%;
      margin-left: -.5rem;
      margin-top: -.5rem;
      background: #3A84D0;
      border-radius: 9999px;
    }
  }
}

