.widget-scroll {
    flex-direction: column;
    display: flex;
    height: 100%;

    p {
        color: #fff;
    }

    .widget-title {
        text-transform: uppercase;
        color: #F28411;
        font-weight: bold;
        line-height: 44px;
        font-size: 20px;
    }

    .widget-date {
        font-weight: 600;
        font-size: 18px;
        line-height: 44px;
        color: #fff;
    }

    .scrollarea .scrollbar-container.vertical {
        background: #E4E5EF;
        border-radius: 5px;
        opacity: 1;
        width: 6px;
    }

    .scrollarea .scrollbar-container.vertical .scrollbar {
        background: linear-gradient(0deg, #1194F3, #1194F3), linear-gradient(0deg, #F3860C, #F3860C), #3F447A;
        border-radius: 5px;
        width: 6px;
        margin-left: 0;
    }

    .scrollarea-content {
        padding-right: 10px;
    }

}

