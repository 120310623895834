@import "proximaNovaWebfontsKit";
@import "icomoon";
@import "variables";

html {
  font-size: 85%;
}
body, html {
  height: 100%;
}

body {
  font-family: 'ProximaNova-Regular', sans-serif;
  background-color: #ffffff;
  color: $essilor-text-color-black;
  border-color: #B2B2B2;

}

header {
  background-color: $essilor-bg-color-blue;
  position: fixed;
  width: 100%;
  z-index: 10;
}

svg {
  max-width: 100%;
}

h1, h2 {
  font-family: 'ProximaNova-Semibold', sans-serif;
  font-size: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  line-height: 120%;

  &.black {
    color: $essilor-text-color-black;
  }
}

h2, h4, h5, h6 {
  font-family: 'ProximaNova-Semibold', sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.12em;
}

h3 {
  font-family: 'ProximaNova-Semibold', sans-serif;
  font-size: 1rem;
  padding: .5rem 0;
  color: #fff;
  letter-spacing: 0.12em;

  &.black {
    color: $essilor-text-color-black;
  }
}

a {
  text-decoration: underline;
}

.h-screen-half {
  height: 50vh;
}

.gradient-blue {
  background: linear-gradient(180deg, #25268b 0%, #572a7a 100%);
}
.gradient-perple {
  background: linear-gradient(60.54deg, #5F237D -5.98%, #341A7D 25.1%, #0D38D1 67.11%, #28A1FA 103.81%);
}

.custom-grey-color {
  color: #575E8A;
}

.submenu  {
  list-style: none;
  margin: 2rem 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;

  @media (max-width: 640px) {
    & {
      flex-direction: column;
    }
  }

  li  {
    background: none;
    border: 2px solid #3A84D0;
    box-sizing: border-box;
    align-items: center;
    text-align: center;
    display: flex;
    margin-right: .5rem;
    flex-wrap: wrap;
    min-height: 7rem;
    padding: 0 1rem;
    color: #3A84D0;
    text-transform: uppercase;

    @media (max-width: 640px) {
      & {
        margin-bottom: 1rem;
        justify-content: center;
      }
    }

    a {
      text-decoration: none;
      height: 100%;
      display: flex;
      align-items: center;
      padding: 1rem 0;
    }

    &.active, &:hover {
      background-color: #3A84D0;
      cursor: pointer;
      color: #fff
    }
  }
}

.sections-menu {
  display: flex;
  margin: 3rem 0;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  border-bottom: 4px solid #DBDADA;

  &__item {
    text-decoration: none;
    padding-bottom: 1rem;
    text-transform: uppercase;
    margin: 0 2rem;
    &.active {
      border-bottom: 4px solid #3A84D0;
      margin-bottom: -4px;
    }

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

.label {
  padding: .5rem 1rem;
  color: #fff;
  &__red {
    background: #C70C7C;
  }
  &__blue {
    background: #1194F3;
  }
  &__green {
    background: #24A791;
  }
  &__orange {
    background: #F88531;
  }
}

.items-result {
  //display: flex;
  display: grid;
  grid-gap: .5rem;
  grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr));
  justify-content: start;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 1.4em;
  @media (max-width: 768px) {
    & {
      grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
    }
  }

  &.w-full {
    //width: calc(100% + 1rem);
  }

  &__item {
    background: rgba(232,240,253,.5);
    padding: 1.4rem;
    //width: calc(100%/3);
    //margin:.5rem;
    text-decoration: none;
    flex-grow: 1;
    flex-basis: 26rem;

    &.item-light-blue {
      background: linear-gradient(0deg, rgba(232, 240, 253, 0.3), rgba(232, 240, 253, 0.3)), #FFFFFF;
      border: 2px solid #E8F0FD;

      &:hover {
        background: linear-gradient(180deg, rgba(232, 240, 253, 0) 0%, #E8F0FD 100%);
        border: 2px solid #3A84D0;
      }
    }

    &.home-items {
      background-color: #56A7DB;
    }

    &.item-white {
      padding: 0;
      background: #fff;
      overflow: hidden;
      position: relative;

      &.box-shadow {
        box-shadow: 0px 0px 15px rgba(36, 36, 44, 0.12);
      }

      img {
        height: 15rem;
      }

      .item-white__time {
        color: #2253A0;
        padding-right: 1rem;
        font-size: 1.1rem;
      }

      .content {
        padding: .5rem 1rem;
        padding-bottom: 1rem;
        margin-bottom: 50px;

        .title {
          font-size: 1.25rem;
          font-weight: 600;
          color: $essilor-text-color-black;
          text-transform: uppercase;
          padding: .5rem 0;
        }

        .description {
          color: rgba(56, 60, 97, 0.7);
          font-size: 0.9rem;
        }
      }

      .bottom-content {
        background: rgb(242, 247, 255);
        padding: 10px;
        position: absolute;
        bottom: 0px;
        text-transform: uppercase;
        color: #56A7DB;

          a {
            text-decoration: none;
          }
      }
    }

    @media (min-width: 768px) {
      & {
        max-width: 37rem;
      }

      &-full {
        max-width: 100%;
      }
    }

    img {
      object-fit: cover;
      height: 12rem;
      width: 100%;
    }

    &-title {
      margin-top: 1rem;
      display: flex;
      flex-flow: row;

      i {
        margin-right: .8rem;
        font-size: 2rem;
      }
      span {
        font-size: 1.2rem;
        color: #383C61;
      }

      .icon-file {
        &-doc {
          color: #56A7DB;
        }
        &-pdf {
          color: #2253A0;
        }
        &-image {
          color: #57A096;
        }
      }
      .icon-download {
        color: #B2B2B2;
        font-size: 2rem;
      }
    }
  }
}

.bg-white-half {
  background: rgba(255, 255, 255, 0.5);
}

.bg-light-light-blue {
  background-color: #e8f0fd;
}

.bg-light-blue {
  background-color: $essilor-bg-color-blue;
}

.bg-light-navy {
  background: rgba(232,240,253,.4);
}

.page-title {
  text-transform: uppercase;
  color: #19212F;
  border-bottom: 3px solid #19212F;
  font-weight: 600;
  font-size: 1.5rem;
  padding: .5rem 0;
  margin-bottom: 2rem;

  &--breadcrumbs {
    margin-bottom: 0.5rem;
  }

  &--second {
    font-size: 1rem;
    margin-top: 2rem;
  }

  &--withoutborder {
    border-bottom: 0px solid #fff;
  }
}

ul.breadcrumbs {
  font-size: 1rem;
  color: $essilor-text-color-grey;
  margin-bottom: 2rem;
  list-style: none;

  text-decoration-line: underline;
  text-transform: capitalize;



  li {
    display: inline-block;
    padding-right: .5rem;

    a:hover {
      color: $essilor-text-color-black;
    }
  }
}

.white-box {
  padding: 1.4rem;

  &.box-shadow {
    box-shadow: 0px 0px 15px rgba(36, 36, 44, 0.12);
  }
}
.blue-light-box {
  padding: 1.4rem;
  background: rgba(232,240,253,.5);
  &__title {
    color: #19212F;
    text-transform: uppercase;
  }
}
.blue-box {
  background: #56A7DB;
  padding: 1.4rem;
  color: #fff;

  &__title {
    color: #fff;
    text-transform: uppercase;
  }
}

.sweep-to-right {
  position: relative;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition: color 100ms;
  transition: color 100ms;

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    //background: linear-gradient(180deg, #EC6012 0%, #E2271B 90%);
    background: linear-gradient(180deg, #44C971 0%, #0586AF 100%);
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition: 300ms ease-out;
    transition: 300ms ease-out;
    color: #ffffff;
  }

  &:hover {
    color: #ffffff;
  }

  &:hover:before {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

.text-essilor-blue {
  color: #3A84D0;
}
.text-essilor-azure {
  color: #1194F3;
}

.text-essilor-navy {
  color: #383C61;
}

.text-essilor-light-navy {
  color: #858FCA;
}
.text-essilor-yellow {
  color: #FBDA64;
}

.text-essilor-orange {
  color: #FBA018;
}

.text-essilor-gray {
  color: #757575;
}

.text-essilor-black {
  color: #19212F;
}

.text-essilor-light-blue {
  color: #56A7DB;
}

.text-essilor-dark-blue {
  color: $essilor-text-color-dark-blue;
}

.text-essilor-green {
  color: #24A791;
}

.filters {
  .selectInput {
    margin-bottom: 0;
  }
}



ul.list-essilor {
  list-style: none;
  margin-left: 1em;
}

ul.list-essilor li::before {
  content: "\2022";
  color: $essilor-bg-color-blue;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}


.title-semibold {
  font-family: 'ProximaNova-Semibold', sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.12em;
  font-size: 1.3rem;

  &__black {
    color: $essilor-text-color-black;
  }

  &__blue {
    color: $essilor-text-color-blue;
  }
}

.pagination-page {
  color: #757575;

  &:hover {
    background-color: $essilor-text-color-blue;
    color: #ffffff;
  }
}

.pagination-arrow {
  color: #757575;
  background-color: #e8f0fd;

  &:hover {
    background-color: $essilor-text-color-blue;
    color: #ffffff;
  }
}

.label__test {
  background-color: #2253a0;
}