.notifications-bell {
  position: relative;

  .notifications-bell-count {
    position: absolute;
    display: block;
    top: -5px;
    right: 2px;
    background: red;
    border: 1px solid red;
    padding: 0px 5px;
    border-radius: 50%;
    font-size: 9px;
  }
}

.notifications-item {
  &__title {
    font-size: 16px;
  }

  &__section {
    border-radius: 10px;
    padding: 5px 20px;
    color: #fff;
    font-size: 15px;
    display: inline-block;
    margin-bottom: 1rem;
    background-color: #57A096;

    &-default {
      background-color: #57A096;
    }

    &-calendar {
      background-color: #2253A0;
    }

    &-library {
      background-color: #E7B17F;
    }

    &-news {
      background-color: #56A7DB;
    }

    &-promotion {
      background-color: #716BB6;
    }

    &-sale_reminder_doctor {
      background-color: #2253A0;
    }

    &-sale_reminder_consultant {
      background-color: #2253A0;
    }
  }

  &__location {
    color: #19212F;
    font-size: 13px;
    margin-bottom: .5rem;
  }
}