.Spinner {
    width: 40px;
    height: 40px;
    animation: Spinner 2.5s infinite linear both;
    left: 50%;
    margin-left: -20px;
    margin-top: -20px;
    top: 50%;
    position: absolute;
}

.SpinnerDot {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    animation: SpinnerDot 2.0s infinite ease-in-out both;
}

.SpinnerDot:before {
    content: '';
    display: block;
    width: 25%;
    height: 25%;
    background-color: #fff;
    border-radius: 100%;
    animation: SpinnerDot-before 2.0s infinite ease-in-out both;
}

.SpinnerDot:nth-child(1) { animation-delay: -1.1s; }
.SpinnerDot:nth-child(2) { animation-delay: -1.0s; }
.SpinnerDot:nth-child(3) { animation-delay: -0.9s; }
.SpinnerDot:nth-child(4) { animation-delay: -0.8s; }
.SpinnerDot:nth-child(5) { animation-delay: -0.7s; }
.SpinnerDot:nth-child(6) { animation-delay: -0.6s; }
.SpinnerDot:nth-child(1):before { animation-delay: -1.1s; }
.SpinnerDot:nth-child(2):before { animation-delay: -1.0s; }
.SpinnerDot:nth-child(3):before { animation-delay: -0.9s; }
.SpinnerDot:nth-child(4):before { animation-delay: -0.8s; }
.SpinnerDot:nth-child(5):before { animation-delay: -0.7s; }
.SpinnerDot:nth-child(6):before { animation-delay: -0.6s; }

@keyframes Spinner {
    100% { transform: rotate(360deg); }
}

@keyframes SpinnerDot {
    80%, 100% { transform: rotate(360deg); }
}

@keyframes SpinnerDot-before {
    50% {
        transform: scale(0.4);
    } 100%, 0% {
          transform: scale(1.0);
      }
}